import React, { useState } from 'react';
import { ServiceItem } from '@tradesorg/component-library-waas-templates.template-1-service-item';
import { SectionHeadingComponent } from '@tradesorg/component-library-waas-templates.base-section-heading';
import { Button } from '@tradesorg/component-library-waas-templates.base-button';
import styles from './index.module.scss';
import { nanoid } from 'nanoid';
import { RequestQuote } from '@tradesorg/component-library-waas-templates.template-1-request-quote';
import { Formik } from 'formik';
import ManElectrical from '@assets/images/template1/man-electrical.png';

const ServiceComponent = ({ data }) => {
    const [startDate, setStartDate] = useState();
    const [time, setTime] = useState();
    const [step, setStep] = useState('day');
    const [indexTime, setIndexTime] = useState();
    const [showModalMobile, setShowModalMobile] = useState(false);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const handleShowRequestModal = () => {
        setShowRequestModal(!showRequestModal)
    }
    const [initialValues, setInitialValues] = useState({
        'cta_name': '',
        'cta_phone': ''
    });

    return (
        <div
            className={`serviceComponentWrapper ${styles.serviceWrapper} h-full pt-20 mx-auto overflow-hidden sm:px-8 xs:px-4 2xl:max-w-screen-2xl`}
            id="services"
        >
            <div className={`headingWrapper`}>
                <SectionHeadingComponent
                    sloganText={data.slogan}
                    heading={data.titleLeft}
                    text={data.titleRight}
                />
            </div>
            <div className="lg:mt-24 sm:px-8 xs:px-4">
                {data.services.map((item, index) => {
                    const borderClass =
                        index === data.services.length - 1 ? styles.boder : styles.boderTop;
                    return (
                        <div
                            className={`${styles.customServiceItem} ${borderClass}`}
                            key={nanoid()}
                        >
                            <ServiceItem
                                title={item.title}
                                content={item.text}
                                serviceImageUrl={item.photo[0] ? item.photo[0].url : ManElectrical}
                            />
                        </div>
                    );
                })}
            </div>
           
            
        </div>
    );
};
export default ServiceComponent;
