import React from 'react';
import ImageSectionHappyClient from '@assets/images/electrician-builder-work-electrician-tool-1.png';
import { SectionHeadingComponent } from '@tradesorg/component-library-waas-templates.base-section-heading';
import ImageEllipse from '@assets/images/ellipse-happly-client.svg';
import ImageRectangle from '@assets/images/rectangle-happy-client.svg';
import { ContentTile } from '@tradesorg/component-library-waas-templates.template-1-content-tile';
import Slider from 'react-slick';
import styles from './index.module.scss';
import Img from 'gatsby-image';
import { nanoid } from 'nanoid';
import SaveTimePlumber from '@assets/images/template1/save-time-plumber.jpg';
import SaveTimePlumberMobile from '@assets/images/template1/save-time-plumber-mobile.jpg';

const HappyClientComponent = ({ data }) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };
    return (
        <div className="HappyClientComponent relative h-auto overflow-hidden">
            <div className="h-full pt-20 lg:pb-32 xs:pb-6 mx-auto overflow-hidden sm:px-8 xs:px-4 2xl:max-w-screen-2xl relative">
                <div className="lg:mb-12 xs:mb-4">
                    <SectionHeadingComponent
                        sloganText={data.slogan}
                        heading={data.titleLeft}
                        text={data.titleRight}
                    />
                </div>

                <div className="md:grid md:grid-cols-2 ">
                    <div className="md:relative md:h-full md:w-full">
                        {/* { data.imageLeft && <Img fluid={data.imageLeft} /> } */}
                        <img src={SaveTimePlumberMobile} className="block md:hidden w-full mx-auto"alt="save time" />
                        <img src={SaveTimePlumber} className="hidden md:block"alt="save time" />

                        {/* <div className={`absolute lg:hidden -left-4 sm:-left-8 w-14 bottom-3`}>
                            <img src={ImageEllipse} alt="" />
                        </div> */}
                        <div className={`inline-block -mt-12 w-28 md:w-auto`}>
                            <img src={ImageRectangle} alt="" />
                        </div> 
                        
                    </div>

                    <div className="gridWrapperContentTitle md:pl-12">
                        <div className="lg:grid grid-cols-2 gap-8 xs:hidden">
                            {data.cards.map((item, index) => {
                                console.log(item)
                                return (
                                    <ContentTile
                                        key={nanoid()}
                                        title={item.name}
                                        description={item.description}
                                    />
                                );
                            })}
                        </div>

                        <div className="xs:block lg:mt-28 md:mt-24 lg:hidden">
                            <Slider {...settings}>
                                {data.cards.map((item, index) => {
                                    const description = item.name;
                                    return (
                                        <div key={nanoid()} className="customContentTitle">
                                            <ContentTile title="" description={description} />
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={`${styles.squareLayout} absolute lg:block xs:hidden`}>
                <div className={`absolute left-10% -top-20%`}>
                    <img className="w-44" src={ImageEllipse} alt="" />
                </div>
                <div className={`absolute left-10% top-30%`}>
                    <img className="w-44" src={ImageRectangle} alt="" />
                </div>
            </div> */}
        </div>
    );
};
export default HappyClientComponent;
