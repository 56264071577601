import React from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import data from '@data/template-1/navigations.json';
import iconFacebook from '@assets/icons/facebook-footer.svg';
import iconLinkedin from '@assets/icons/linkedin-footer.svg';
import iconInstagram from '@assets/icons/intagram-footer.svg';
import Img from 'gatsby-image'
import { nanoid } from 'nanoid';
import {formatPhoneNumber} from '../../../utils/formatPhoneNumber'

const FooterComponent = ({data : strapiData , navigations}) => {
    
    const footerNavigations = data.footer
    console.log(navigations)
    const handleRenderIcon = (key) => {
        if (key.includes('facebook')) {
            return iconFacebook;
        }
        if (key.includes('intagram')) {
            return iconInstagram;
        }
        return iconLinkedin;
    };
    return (
        <footer className="w-full overflow-hidden bg-white" aria-labelledby="footerHeading">
            <h2 id="footerHeading" className="sr-only">
                Footer
            </h2>
            <div className="px-4 md-1:py-12 mx-auto 2xl:max-w-screen-2xl lg:py-16 sm:px-6 xs:px-5 xl:px-8">
                <div className="lg:grid lg:grid-cols-5 lg:gap-8">
                <div
                className={` items-center text-center grid font-normal`}
            >
            <Img className="lg:w-40  xs:w-20" fluid={strapiData.logo}/>
            </div>
                    <div className="mt-12 grid grid-cols-1 gap-8 xl:mt-0">
                        <div>
                            {footerNavigations && footerNavigations.col1 && (
                                <ul className="mt-4 space-y-4">
                                    {navigations.slice(0,(navigations.length/2)).map((item, index) => {
                                        if (item.show){
                                            return (
                                                <li key={nanoid()} className="lg:text-left xs:text-center">
                                                <AnchorLink
                                                    to={item.path}
                                                    title={item.title}
                                                    className="text-sm not-italic font-normal tracking-normal lg:text-left xs:text-center leading-9"
                                                />
                                            </li>
                                            )

                                        }
                                    }
                                       
                                      
                                    
                                    )}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="mt-12 grid grid-cols-1 gap-8 xl:mt-0">
                        <div>
                            {footerNavigations && footerNavigations.col1 && (
                                <ul className="mt-4 space-y-4">
                                    {navigations.slice((navigations.length/2),navigations.length).map((item, index) => {
                                        if(item.show){
                                            return(
                                                <li key={nanoid()} className="lg:text-left xs:text-center">
                                                <AnchorLink
                                                    to={item.path}
                                                    title={item.title}
                                                    className="text-sm not-italic font-normal tracking-normal lg:text-left xs:text-center leading-9"
                                                />
                                            </li>
                                            )
                                           
                                        }

                                    }
                                       
                                    )}
                                </ul>
                            )}
                        </div>
                    </div>
                   

                    <div className="mt-12 grid-cols-2 xl:mt-0 text-gray-700 ">
                        <div className="grid grid-cols-1">
                            <div className="items-center text-left">
                                <h3 className="text-base not-italic font-normal tracking-normal lg:text-left xs:text-center leading-6">
                                    {footerNavigations.forLetTalk.title}
                                </h3>
                                <a href={`tel:${strapiData.phone}`}>
                                <p className="mt-5 text-2xl not-italic font-medium tracking-normal lg:text-left xs:text-center leading-7">
                                    {formatPhoneNumber(strapiData.phone)}
                                  
                                </p>
                                </a>
                            </div>
                        </div>

                        <div className="mt-10 grid grid-cols-1 text-gray-700">
                            <div className="items-center lg:text-left xs:text-center">
                                <h3 className="text-base not-italic font-normal tracking-normal lg:text-left xs:text-center leading-6">
                                    {footerNavigations.forEmail.title}
                                </h3>
                                <a href={`mailto:${strapiData.email}`}>
                                <p className="mt-5 text-2xl not-italic font-medium tracking-normal leading-7 lg:text-left xs:text-center">
                                {strapiData.email}
                                </p>
                                </a>
                            </div>
                        </div>
                    </div>
                        {!!strapiData.socialProfiles.length && <div className="mt-12 grid-cols-1 xl:mt-0">
                        <div className="items-center text-left">
                            <h3 className="text-base not-italic font-normal tracking-normal lg:text-left xs:text-center leading-6">
                                {footerNavigations.forSocialMedia.title}
                            </h3>

                            <div className="flex mt-5 cursor-pointer space-x-10  xs:justify-center lg:justify-start">
                                {strapiData.socialProfiles.map((item) => {
                                    return (
                                        <a href={item.url} target="_blank"  rel="noopener">                                        
                                        <img
                                            key={nanoid()}
                                            src={handleRenderIcon(item.name)}
                                            alt={`icon-${item.name}`}
                                        />
                                        </a>

                                    );
                                })}
                            </div>
                        </div>
                    </div>}
                    
                </div>
                <div className="justify-center pt-8 mx-auto mt-12 border-t border-gray-200 lg:flex xs:grid">
                    {footerNavigations.coppyRight.content.map((item) => {
                        return (
                            <a
                            rel="noopener"
                                className="mx-5 text-sm not-italic font-normal tracking-normal text-gray-900 underline lg:text-left xs:text-center leading-6 lg:mt-0 xs:mt-5"
                                key={nanoid()}
                                href={item.link}
                                target="_blank"
                            >
                                {item.title}
                            </a>
                        );
                    })}
                    {footerNavigations?.coppyRight?.power && (
                        <p className="mx-5 text-sm not-italic font-normal tracking-normal text-gray-900 lg:text-left xs:text-center leading-6 lg:mt-0 xs:mt-5">
                            {footerNavigations.coppyRight.power} <a href="https://www.trades.org" target="_blank"  rel="noopener">trades.org</a>
                        </p>
                    )}
                </div>
            </div>
        </footer>
    );
};

export default FooterComponent;