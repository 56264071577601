import React, { useEffect, useState } from 'react';
import data from '@data/template-1/homepage.json';
import { graphql } from 'gatsby';
import Layout from '../../components/commons/layout';
import Hero from '../../components/commons/hero';
import HappyClientComponent from '../../containers/template-1/home-page/happy-clients';
import ServiceComponent from '../../containers/template-1/home-page/services';
import Testimonials from '../../containers/template-1/home-page/testimonials';
import ComplitedProjectComponent from '../../containers/template-1/home-page/complited-projects';
import Faqs from '../../containers/template-1/home-page/faqs';
import OurProcessComponent from '../../containers/template-1/home-page/our-process';
import ServiceAreaComponent from '../../containers/template-1/home-page/service-area';
import MaskGroup1 from '@assets/images/template1/MaskGroup.jpg'
import MaskGroup2 from '@assets/images/template1/MaskGroup-2.jpg'
import { getRandomFaqs } from '../../utils/getRandomFaqs';
import TabHero from '@assets/images/template1/hero_1.png';
import TabHero2 from '@assets/images/template1/hero_2.png';
import MobileHero from '@assets/images/template1/plumber-making.png';
import MobileHero2 from '@assets/images/template1/plumber-making2.png';
import { getRandomUsp } from '../../utils/getRandomUsp';


const IndexPage = ({
    data: {
        allStrapiWebsites: { nodes },
        allStrapiFaqs,
        allStrapiUniqueSellingPropositions
    },
}) => {
    const [usp,setUsp] = useState(null)
    useEffect(()=>{
        setUsp(getRandomUsp(allStrapiUniqueSellingPropositions.nodes))
    },[])
    const businessData = nodes[0].business;
    const businessLayoutData = {
        logo: businessData?.logoImage?.localFile?.childCloudinaryAsset?.fluid ?? null,
        phone: businessData.phone,
        email: nodes[0].trade_pro.email,
        socialProfiles: businessData.socialProfiles,
        ...businessData
    };
    const heroData = {
        usp : usp,
        title: businessData.displayName,
        subTitle: businessData.shortDescription ? businessData.shortDescription : null,
        image:  MaskGroup1,
        tabImage: TabHero,
        mobileImage: MobileHero
    };

    const heroData2 = {
        usp : usp,
        title: businessData.displayName,
        subTitle: businessData.shortDescription ? businessData.shortDescription : null,
        image:  MaskGroup2,
        tabImage: TabHero2,
        mobileImage: MobileHero2
    };


    const imageLeft = businessData.highlightedServices[0].photo[0] ?
        businessData.highlightedServices[0].photo[0].localFile.childCloudinaryAsset.fluid :
        null;

    const businessFeatures = {
        slogan: 'Lorem Ipsum',
        titleLeft: 'Business Amenities',
        titleRight: '',
        cards: businessData.businessFeatures,
        imageLeft
    };

    const businessServices = {
        ...data.sectionService,
        services: businessData.highlightedServices,
    };

    const imageRight = businessData.highlightedServices[1]?.photo[0] ?
        businessData.highlightedServices[1].photo[0].localFile.childCloudinaryAsset.fluid :
        null;

    const businessProcess = {
        ...data.ourProcess,
        processes: businessData.process ? businessData.process : [],
        imageRight
    };

    const businessReviews = {
        ...data.feedbacks,
        feedbacks: businessData.feedbacks,
    };

    const businessFaqs = {
        faqs: getRandomFaqs(allStrapiFaqs.nodes) ,
    };
    
    const businessServiceArea = {
        ...data.area,
        radius: businessData.radiusServed ? businessData.radiusServed : 20,
        phone: businessData.phone,
        email: nodes[0].trade_pro.email,
        address: businessData.address,
    };
    const seoData = {
        title: businessData.displayName,
        description: businessData.shortDescription ?? null,
        domain: nodes[0].websiteDomain.name,
        logo: businessData.logoImage?.url ?? null,
        previewImage: businessData.coverImage?.url ?? null,
    };
    return (
        <Layout style={{ background: '#D0ECF5' }} data={businessLayoutData} seoData={seoData} faqs={businessFaqs}>
            <Hero isHome showCalendar heroData={heroData} />
            <HappyClientComponent data={businessFeatures} />
            <ServiceComponent data={businessServices} />
          
           {!!businessProcess.processes.length &&  <OurProcessComponent data={businessProcess} />}
           {!!businessReviews.feedbacks.length && <Testimonials data={businessReviews} />}


          
            <Faqs data={businessFaqs} />
            <ServiceAreaComponent data={businessServiceArea} />
            <Hero heroData={heroData2} />
        </Layout>
    );
};
export const query = graphql`
    query ($strapiId: Int!) {
        allStrapiWebsites(filter: { strapiId: { eq: $strapiId } }) {
            nodes {
                websiteDomain {
                    name
                }
                trade_pro {
                    email
                }
                business {
                    logoImage {
                        url
                        localFile {
                            childCloudinaryAsset {
                                fluid(maxWidth: 200) {
                                    ...CloudinaryAssetFluid
                                }
                            }
                        }
                    }
                    phone
                    socialProfiles {
                        id
                        name
                        url
                    }
                    displayName
                    shortDescription
                    businessFeatures {
                        name
                        description
                    }
                    coverImage {
                        url
                        localFile {
                            childCloudinaryAsset {
                                fluid(maxWidth: 1100) {
                                    ...CloudinaryAssetFluid
                                }
                            }
                        }
                    }
                    highlightedServices {
                        
                        text
                        title
                        photo {
                            url
                            localFile {
                                childCloudinaryAsset {
                                    fluid(maxWidth: 1400) {
                                        ...CloudinaryAssetFluid
                                    }
                                }
                            }
                        }
                    }
                    images {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 2000) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                    process {
                        longDescription
                        shortDescription
                        title
                    }
                    feedbacks {
                        review
                        fullName
                        img {
                            url
                        }
                    }
                    radiusServed
                    address {
                        longitude
                        singleLineAddress
                        latitude
                    }
                }
            }
        }
        allStrapiFaqs {
            nodes {
              websites {
                id
              }
              question
              answer
            }
          }
          allStrapiUniqueSellingPropositions(filter: {websites: {elemMatch: {id: {eq: $strapiId}}}}) {
            nodes {
              usp_options {
                option
                id
              }
            }
          }
    }
`;

export default IndexPage;
