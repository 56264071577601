import React, { useState } from 'react';
import { SectionHeadingComponent } from '@tradesorg/component-library-waas-templates.base-section-heading';
import ImageEllipseBlue from '@assets/images/ellipse1-4.svg';
import ImageEllipseBlack from '@assets/images/ellipse1-2-black.svg';
import ImageEllipse from '@assets/images/ellipse-full-26-26.svg';
import ImageEllipseMini from '@assets/images/ellipse-full-16-16.svg';
import { Button } from '@tradesorg/component-library-waas-templates.base-button';
import styles from './index.module.scss';
import Img from 'gatsby-image';
import { nanoid } from 'nanoid';
import { RequestQuote } from '@tradesorg/component-library-waas-templates.template-1-request-quote';
import { Formik } from 'formik';
import { ProcessItem } from '@tradesorg/component-library-waas-templates.template-1-process-item';
import CarpenterWorking from '@assets/images/template1/carpenter-working.png';
import Ellipse42 from '@assets/images/template1/ellipse-42.png';
import BlueCircle from '@assets/images/template1/blue-circle.png';
import ElectricianWorking from '@assets/images/template1/electrician-working.png';
import BrownCircle from '@assets/images/template1/brown-circle.png';
import YoungMonsters from '@assets/images/template1/young-mounters.png';
import BlackEllipse from '@assets/images/template1/black-ellipse.png';
import Markdown from 'markdown-to-jsx';

const OurProcessComponent = ({ data }) => {
    const [startDate, setStartDate] = useState();
    const [time, setTime] = useState();
    const [step, setStep] = useState('day');
    const [indexTime, setIndexTime] = useState();
    const [showModalMobile, setShowModalMobile] = useState(false);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const handleShowRequestModal = () => {
        setShowRequestModal(!showRequestModal)
    }
    const [initialValues, setInitialValues] = useState({
        'cta_name': '',
        'cta_phone': ''
    });
    console.log(data)

    const renderProcess = () => {
        return (
            <div>
                <div className={`grid our-process-item  ${styles.ourProcessItem}`}>
                    {data.processes.map((item, index) => {
                        return (
                            <ProcessItem
                                className={`h-full px-4 py-12 grid grid-cols-1 gap-5`}
                                key={nanoid()}
                                title={item.title}
                                context={ <Markdown 
                                >{item.longDescription ? item.longDescription : item.shortDescription ? item.shortDescription : ''}</Markdown>}
                            />
                        );
                    })}
                </div>
                {/* <div className="lg:h-16 xs:h-12 w-56 grid px-4 lg:m-0 xs:m-auto xs:justify-center md:justify-start">
                    <Button style={{ height: 'auto', width: '212px' }} onClick={handleShowRequestModal}>
                        <p className="text-sm not-italic font-normal tracking-normal text-center leading-5">
                            REQUEST A QUOTE
                        </p>
                    </Button>
                </div> */}
            </div>
        );
    };
    return (
        <div id="our-process" className="lg:pt-20 pt-10 overflow-hidden">
            <div className="2xl:max-w-screen-2xl mx-auto px-8">
                <SectionHeadingComponent
                    sloganText={data.slogan}
                    heading={data.titleLeft}
                    text={data.titleRight}
                    centered
                />
            </div>

            <div className="mt-18 2xl:max-w-screen-2xl px-4  grid xs:grid-cols-1 lg:grid-cols-2 gap-6 m-auto">
                <div className="xs:hidden md:block px-4">{renderProcess()}</div>
                <div className="px-4">
                    { data.imageRight && <Img fluid={data.imageRight} className="lg:z-1" /> }
                    <div className="flex">
                        <div className={`sm:w-1/2 relative inline-block mt-32`}>
                            <img src={Ellipse42} alt="ellipse" className="absolute top-2/4 sm:top-0 -left-1/2 -mt-28 -sm:left-0 sm:right-0 sm:mx-auto -z-10" style={{zIndex: "-10"}} />
                            <img src={CarpenterWorking} alt="carpentar working" />
                        </div>

                        <div className={`w-1/2 relative hidden sm:inline-block mt-16 xl:pr-16`}>
                            <img src={BlueCircle} alt="carpentar working" className="ml-auto mb-11" />
                            <img src={ElectricianWorking} alt="carpentar working" className="ml-auto" />
                            <img src={BrownCircle} alt="carpentar working" className="mx-auto mt-20" />
                        </div>
                    </div>

                    <div>
                        <div className={`w-1/2 relative hidden sm:inline-block mt-32`}>
                            
                        </div>

                        <div className={`sm:w-1/2 relative inline-block xl:pr-16 w-full h-full`}>
                            <img src={BlackEllipse} alt="Black Ellipse" className="w-1/3 sm:w-auto ml-auto absolute right-0 -top-20" />
                            <img src={YoungMonsters} alt="YoungMonsters" className="ml-auto relative hidden sm:inline-block top-5" />
                        </div>
                    </div>
                </div>

               
                <div className="xs:block md:hidden">{renderProcess()}</div>
            </div>

        </div>

    );
};

export default OurProcessComponent;
