import React, { Fragment, useState, useEffect } from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon, SearchIcon, MailIcon } from '@heroicons/react/outline';
import data from '@data/template-1/navigations.json';
import iconSearchHeader from '@assets/icons/search-header.svg';
import { Link } from 'gatsby';
import { nanoid } from 'nanoid';
import Img from 'gatsby-image';
import styles from './index.module.scss';
import { PhoneIcon } from '@heroicons/react/solid';

const Logo = ({ logo }) => {
    return (
        <>
        {logo && <Link to="/" aria-current="site-logo" className="flex">
            <div className={` items-center text-center grid font-normal ${styles.logo}`}>
                <Img className="xl:w-40  xs:w-20" fluid={logo} />
            </div>
        </Link>}
        </>
        
    );
};

const ItemsMenuLeft = ({ navigations, setOpen }) => {
    return (
        <>
            <Popover.Group
                as="nav"
                className="flex xs:space-x-0 xl:space-y-0 xs:space-y-5 xl:space-x-10 xl:flex xs:grid grid-cols-1"
            >
                { navigations?.map((item) => {
                    if(item.show){
                        return (
                            <AnchorLink
                                key={nanoid()}
                                to={item.path}
                                title={item.title}
                                className="text-sm not-italic font-normal tracking-normal text-left text-gray-900 leading-4 hover:text-gray-500"
                                onClick={() => setOpen && setOpen(false)}
                            />
                        );
                    }
                        
                    })}
            </Popover.Group>
        </>
    );
};
const IconSearch = () => {
    return (
        <div className="mx-6 cursor-pointer">
            <img src={iconSearchHeader} alt="icon-search" />
        </div>
    );
};

const ItemsMenuRight = ({ data }) => {
    return (
        <div className="xl:flex xs:grid items-center">
            {/* 
                <div className="">
                    <Link to="#" className="mr-4 text-sm not-italic font-normal tracking-normal text-left text-gray-900 leading-4 hover:text-gray-500">Project Financing</Link>
                    <Link to="#" className="mr-4 text-sm not-italic font-normal tracking-normal text-left text-gray-900 leading-4 hover:text-gray-500">Make A Payment</Link> 
                    <span className="ml-4 mr-8 text-gray-300">|</span>
                
                <span className="ml-4 mr-4 "><SearchIcon className="w-6 h-6 inline-block" /></span>
            </div>
            */}

            <a
             rel="noopener"
                href={`tel:${data?.phone}`}
                className="inline-flex items-center justify-center xl:w-40 xs:w-36 h-16 xl:ml-8 xs:ml-0 text-sm not-italic font-bold tracking-normal text-center border border-gray-900 border-solid rounded-none shadow-sm hover:bg-white leading-5"
            >
                CALL US
            </a>
        </div>
    );
};

const NavbarComponent = (props) => {
    const [open, setOpen] = useState(false);

    const [scrolled,setScrolled] = useState(false);

    const handleScroll = () => {
        if(typeof window != "undefined"){
            const offset = window.scrollY;
            if(offset > 150 ) {
                setScrolled(true);
            }
            else {
                setScrolled(false);
            }
        }
        

        
    }

    useEffect(() => {
        if(typeof window != "undefined"){
            window.addEventListener('scroll', handleScroll)
        }
       
    })

    return (
        <Popover className={`relative ${scrolled && `scrolled`}`} {...props}>
            <>
                <div
                    className="absolute inset-0 z-10 shadow-md pointer-events-none"
                    aria-hidden="true"
                />
                <div className="relative z-20" >
                    <div className="flex items-center justify-between h-auto  mx-auto 2xl:max-w-screen-2xl sm:px-6  xs:px-5  xs:py-4 xl:px-8 md:justify-between ">
                        {/* <Logo logo={props.logo} /> */}

                        <div className="flex justify-end xl:hidden">
                            <div className="xl:hidden hidden">
                                <IconSearch />
                            </div>

                            <div onClick={() => setOpen(true)} className="-my-2 -mr-2 xl:hidden">
                                <Popover.Button
                                    className={`inline-flex items-center justify-center  rounded-md focus:outline-none focus:ring-2 focus:ring-inset`}
                                >
                                    <span className="sr-only">Open menu</span>
                                    <MenuIcon className="w-8 h-8" aria-hidden="true" />
                                </Popover.Button>
                            </div>
                        </div>

                        <div className="flex justify-end xl:hidden">
                            <div className="-my-2 flex">
                                <a href={`tel:${data?.phone}`}>
                                    <PhoneIcon className="h-5 w-5 mr-4 " />
                                </a>
                                <a href={`mailto:${data?.email}`}>
                                    <MailIcon className="h-5 w-5" />
                                </a>
                            </div>
                        </div>

                        <div className="hidden xl:flex-1 xl:flex xl:items-center xl:justify-between">
                            <ItemsMenuLeft navigations={props.navigations} />
                            <ItemsMenuRight data={props} />
                        </div>
                    </div>
                </div>

                <Transition
                    show={open}
                    as={Fragment}
                    enter="duration-200 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        static
                        className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right xl:hidden z-20"
                    >
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                            <div className="pt-5 pb-6 px-5">
                                <div className="flex items-center justify-between">
                                    <Logo logo={props.logo} />
                                    <div onClick={() => setOpen(false)} className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                            <span className="sr-only">Close menu</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                            </div>
                            <div className="py-6 px-5 space-y-6">
                                <div className="flex justify-between">
                                    <ItemsMenuLeft
                                        navigations={props.navigations}
                                        setOpen={setOpen}
                                    />
                                    <div className={`${styles.rectangle}`} />
                                    <ItemsMenuRight />
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>
        </Popover>
    );
};

export default NavbarComponent;
