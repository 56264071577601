import React, { useEffect, useState, useRef } from 'react';
import { SectionHeadingComponent } from '@tradesorg/component-library-waas-templates.base-section-heading';
import { FaqItem } from '@tradesorg/component-library-waas-templates.template-1-faq-item';
import { InputTemplate } from '@tradesorg/component-library-waas-templates.base-input-template';
import styles from './index.module.scss';
import { nanoid } from 'nanoid';
import FaqBanner from '@assets/images/template1/faq-banner.png';
import FaqBlackEllipse from '@assets/images/template1/faq-balck-ellipse.png';
import FaqPinkEllipse from '@assets/images/template1/faq-pink-ellipse.png';

const Faqs = ({ data }) => {
    const [faqState, setFaqState] = useState([]);
    const [question, setQuestion] = useState("");
    const typingTimeOutRef = useRef(null);
   
    

    useEffect(() => {
        setFaqState(data.faqs.map((item) => false));
    }, []);

    const handleState = (index, value) => {
        const prev = faqState;
        prev[index] = value;
        setFaqState([...prev]);
    };

    const handleChange = (e) => {
      
        
        
            setQuestion(e?.target?.value);
            
        
    };

    

    return (
        <div
            className="faqsWrapper lg:py-12 pt-0 sm:px-8 xs:px-4 2xl:max-w-screen-2xl mx-auto"
            id="faq"
        >
            <div className="flex items-center flex-wrap">
                <div className="w-full md:w-1/2">
                    <SectionHeadingComponent
                        
                        heading="FAQs"
                        text=""
                        half
                    />
                </div>
                <div className="w-full md:w-1/2">
                    <div className={`${styles.swapperInput} w-full`}>
                       
                            <InputTemplate
                                classNames={{ input: 'border-0-important' }}
                                name="question"
                                id="question"
                                value={question}
                                onChange={handleChange}
                                placeholder="What do you want to ask us?"
                                style={{ height: '70px', backgroundColor: '#F3F6FB' }}
                                LeftComponent={
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M19.8779 18.6996L14.0681 12.8897C15.17 11.5293 15.8334 9.79975 15.8334 7.9167C15.8334 3.55145 12.2819 0 7.91666 0C3.55141 0 0 3.55145 0 7.9167C0 12.2819 3.55145 15.8334 7.9167 15.8334C9.79975 15.8334 11.5293 15.17 12.8897 14.0681L18.6996 19.878C18.8623 20.0407 19.1261 20.0407 19.2889 19.878L19.878 19.2888C20.0407 19.1261 20.0407 18.8623 19.8779 18.6996ZM7.9167 14.1667C4.47024 14.1667 1.66668 11.3631 1.66668 7.9167C1.66668 4.47024 4.47024 1.66668 7.9167 1.66668C11.3631 1.66668 14.1667 4.47024 14.1667 7.9167C14.1667 11.3631 11.3631 14.1667 7.9167 14.1667Z"
                                            fill="#81B0C0"
                                        />
                                    </svg>
                                }
                            />
                        
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 mx-auto">
                    <div className="relative inline-block">
                        <img src={FaqPinkEllipse} alt="faq banner" className="absolute right-0 hidden md:inline-block" />
                        <img src={FaqBanner} alt="faq banner" />
                        <img src={FaqBlackEllipse} alt="faq banner" className="absolute hidden md:inline-block left-0 top-1/2 -ml-8" />
                    </div>
                </div>
                
                <div className="w-full md:w-1/2 mx-auto">
                    <div className={`mt-8`}>
                         <div className="faqItemWrapper md:space-y-0 grid grid-cols-1">
                            {data.faqs.filter(item=> item.question.toLowerCase().includes(question.toLowerCase())).map((item, index) => (
                                <FaqItem
                                    key={nanoid()}
                                    title={item.question}
                                    content={item.answer}
                                    active={faqState[index]}
                                    handleState={handleState}
                                    index={index}
                                />
                            ))}
                        </div>
                    
                    </div>
                    
                </div>
            </div>
       
        </div>
    );
};

export default Faqs;
