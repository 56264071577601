import React from 'react';
import { SectionHeadingComponent } from '@tradesorg/component-library-waas-templates.base-section-heading';
import ImgEllipse from '@assets/images/ellipse47.svg';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Circle } from 'react-google-maps';

import styles from './index.module.scss';
import { formatPhoneNumber } from '../../../../utils/formatPhoneNumber';

const ServiceAreaComponent = ({ data }) => {
    const MyMapComponent = withScriptjs(
        withGoogleMap((props) => (
            <GoogleMap defaultZoom={10} defaultCenter={{ lat: parseFloat(data.address?.latitude) , lng: parseFloat(data.address?.longitude) }}>
                {props.isMarkerShown && <Marker position={{ lat: parseFloat(data.address?.latitude) , lng: parseFloat(data.address?.longitude) }} />}

                <Circle
                    defaultCenter={{ lat: parseFloat(data.address?.latitude) , lng: parseFloat(data.address?.longitude) }}
                    radius={parseInt(data.radius) * 100}
                    options={{
                        strokeColor: '#FF0000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#FF0000',
                        fillOpacity: 0.35,
                    }}
                />
            </GoogleMap>
        )),
    );

    return (
        <div className="relative pt-0 overflow-hidden" id="contact">
            <div className="grid md:grid-cols-2 xs:grid-cols-1 2xl:max-w-screen-2xl justify-center mx-auto">
                <div className="lg:justify-center items-center flex xs:my-8 xs:px-8 lg:px-0 lg:my-0">
                    <div className=" flex flex-col">
                    <SectionHeadingComponent
                        sloganText={data.slogan}
                        heading={data.titleLeft}
                        text=""
                        half
                    />
                    <a href={`tel:${data?.phone}`}>
                     <p className="mt-17px not-italic tracking-normal text-left  leading-4">
                     {formatPhoneNumber(data.phone)}
                    
                </p>
                </a>
                <a href={`mailto:${data?.email}`}>
                <p className="mt-17px not-italic tracking-normal text-left leading-4">
                    { data?.email }
                </p>
                </a>
                <p className="mt-17px not-italic tracking-normal text-left leading-4">
                    { data?.address?.singleLineAddress }
                </p>
                </div>
                </div>
                
                <div className={`${styles.swapperMap}`}>
                    <MyMapComponent
                        isMarkerShown
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${process.env.GATSBY_G_MAP}&libraries=geometry,drawing,places`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `400px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </div>
                <div className={`${styles.imgEllipse} md:block xs:hidden`}>
                    <img src={ImgEllipse} alt="" />
                </div>
            </div>
        </div>
    );
};
export default ServiceAreaComponent;
