import React, { useState, useEffect, useRef } from 'react';
import { RequestQuote } from '@tradesorg/component-library-waas-templates.template-1-request-quote';
import { FeatureBox } from '@tradesorg/component-library-waas-templates.template-1-feature-box';
import DiscountIcon from '../../../assets/images/discount_icon.png';
import styles from './index.module.scss';
import Img from 'gatsby-image';
import { Formik } from 'formik';
import { requestQuote } from '../../../utils/requestQuote';
import SuccessModal from '../successModal';

const Hero = (props) => {
    const [step, setStep] = useState('day');
    const [indexTime, setIndexTime] = useState();
    const [showModal, setShowModal] = useState(false);
    const [initialValues, setInitialValues] = useState({
        cta_name: '',
        cta_phone: '',
        cta_email : ''
    });
    const [isLoading,setLoading] = useState(false)
     
    let errorMessage;

    return (
        <div
            className={`heroComponentWrapper ${styles.heroWrapper} h-auto lg:h-700px `}
        >
            <div className="h-full sm:px-6  xs:px-5 xl:px-8 mx-auto 2xl:max-w-screen-2xl">
                <div className="flex flex-wrap h-full lg:flex-nowrap lg:pt-32 lg:items-start lg:justify-between">
                    <div className="relative flex items-center w-full py-4 pr-4 xs:pt-14 lg:text-left lg:w-2/5 justify-start z-10">
                        <div className="w-full">
                            <h2 className="mt-4 lg:text-xl text-orange xs:text-lg font-medium">{props.heroData.title}</h2>
                            
                            <h1 className="mt-4 lg:text-5xl xs:text-3xl font-medium">
                                {props.heroData?.usp?.option}
                            </h1>
                            { props.heroData && props.heroData.subTitle && <p className="lg:mt-8 xs:mt-4 mb-8">{props.heroData?.subTitle}</p> }
                        </div>
                        <Img
                            className={`absolute top-0 bottom-0 left-0 right-0 object-contain bg-no-repeat lg:hidden ${
                                props?.isHome ? styles.imageMobileHome : styles.imageMobile
                            }`}
                            fluid={props.heroData?.image}
                        />
                    </div>
                    <div
                        className="absolute top-50% left-50% -translateXY-50% hidden lg:block lg:h-full z-0 lg:w-full bg-contain bg-no-repeat bg-center"
                        style={{
                            backgroundImage: `url(${ props.heroData?.image })`,
                        }}
                    />
                    
                    <div className="w-1/2 hidden sm:flex items-end lg:hidden xs:pb-12">
                        <img src={ props.heroData?.tabImage } alt="Hero" className="w-3/4	" />
                    </div>

                    <div className="ml-auto inline-block sm:hidden">
                        <img src={props.heroData?.mobileImage} alt="PlumberMaking" />
                    </div>
                    
                    <div className="requestQuoteWRapper sm:mt-6 w-full sm:w-1/2 inline-block lg:w-full xs:pb-12 ">
                        <Formik initialValues={initialValues}  onSubmit={async(values, actions) => { 

                            if(!!values.cta_email.length || !!values.cta_phone.length){
                                setLoading(true)
                                const response = await requestQuote({name : values.cta_name , phone_number : values.cta_phone , email : values.cta_email})
                                try {
                                    if( response.status === "SUCCESS"){
                                        setShowModal(true)
                                        setLoading(false)
                                    }
                                }
                                catch{
                                    console.log(response)
                                    setLoading(false)
                                }
                            }
                            else{
                                errorMessage = true;
                                
                            }          
       }}>
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => {
                                
                                return (
                                    <RequestQuote
                                        showCalendar={props.showCalendar}
                                        step={step}
                                        setStep={setStep}
                                        indexTime={indexTime}
                                        setIndexTime={setIndexTime}
                                        errorMessage={errorMessage ? 'Email or phone number is required' : null}
                                        values={values}
                                        onSubmit={handleSubmit}
                                        onChange={handleChange}
                                        isLoading={isLoading}
                                    />
                                );
                            }}
                        </Formik>
                    </div>
                
                </div>
            </div>
           {showModal && <SuccessModal setShowModal={setShowModal}/>} 
            <div className="absolute bottom-0 left-0 items-center justify-end hidden w-4/12 pr-12 bg-white lg:hidden">
                <FeatureBox icon={DiscountIcon} />
            </div>
        </div>
    );
};
export default Hero;
