import React, { useState } from 'react';
import { SectionHeadingComponent } from '@tradesorg/component-library-waas-templates.base-section-heading';
import Quotes from '@assets/images/straight-quotes 1.png';
import Background from '@assets/images/testimonials_background.png';
import Slider from 'react-slick';
import styles from './index.module.scss';
import { nanoid } from 'nanoid';

const Testimonials = ({ data }) => {
    const [slick, setSlick] = useState(null);
    const params = {
        slidesToScroll: 1,
        loop: true,
    };
    const handleSlider = (index) => {
        slick.slickGoTo(index);
    };

    return (
        <div
            className="py-12 sm:px-8 xs:px-4 2xl:max-w-screen-2xl mx-auto testimonials"
            id="testimonials"
        >
            <SectionHeadingComponent
                sloganText="Loram Ipsum"
                heading={data.titleLeft}
                text={data.titleRight}
                centered
            />
            <div className="py-2 relative" style={{ backgroundImage: `url(${Background})` }}>
                <div className="hidden absolute top-0 right-0 bottom-0 left-0 lg:grid grid-cols-4">
                    <div className="col-start-1 col-end-2 grid grid-rows-3 grid-cols-1 pr-12">
                        {data?.feedbacks?.slice(0, 3)?.map((item, index) => (
                            <div key={nanoid()} className={`flex items-center justify-start `}>
                                <div
                                    onClick={() => handleSlider(index)}
                                    style={{ backgroundImage: `url("${item.img[0].url}")` }}
                                    className={`rounded-full bg-contain cursor-pointer ${styles.avatar60}`}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="col-start-4 col-end-5 grid grid-rows-3 grid-cols-1 pl-12">
                        {data?.feedbacks?.slice(3, 6)?.map((item, index) => (
                            <div key={nanoid()} className={`flex items-center justify-start`}>
                                <div
                                    onClick={() => handleSlider(index)}
                                    style={{ backgroundImage: `url("${item.img[0].url}")` }}
                                    className={`rounded-full bg-contain cursor-pointer ${styles.avatar60}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="max-w-2xl mx-auto z-10 overflow-hidden">
                    <Slider ref={(c) => setSlick(c)} {...params}>
                        {data?.feedbacks?.map((item) => (
                            <div key={nanoid()} className="flex flex-col items-center h-full">
                                <div
                                    className={`rounded-full  mx-auto bg-contain bg-center relative ${styles.avatar}`}
                                    style={{ backgroundImage: `url("${item.img[0].url}")` }}
                                >
                                    <div
                                        className={`absolute rounded-full  flex items-center justify-center ${styles.quotes}`}
                                    >
                                        <img alt="quote-icon" src={Quotes} />
                                    </div>
                                </div>
                                <p className="mt-8 text-center">{item.review}</p>

                                <h2 className="text-2xl text-center mt-8">{item.fullName}</h2>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
